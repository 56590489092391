<template>
  <section v-loading="listLoading">
    <el-row :gutter="20" :span="24" class="noComtractApporvalDialog">
      <el-col :span="17" style="padding-bottom: 55px">
        <el-collapse accordion v-model="activeNames">
          <!--            开票信息-->
          <el-collapse-item title="开票基本信息" name="2">
            <el-form label-width="110px" label-position="left">
              <el-row>
                <el-col :span="11">
                  <el-form-item label="客户名称：">{{
                    info.companyName ? info.companyName : "-"
                  }}</el-form-item></el-col
                >
                <el-col :offset="1" :span="11">
                  <el-form-item label="项目名称：">{{
                    info.projectName ? info.projectName : "-"
                  }}</el-form-item></el-col
                >
                <el-col :span="11">
                  <el-form-item label="开票类型：">{{
                    info.financeTask.payType ? info.financeTask.payType : "-"
                  }}</el-form-item></el-col
                >
                <el-col :offset="1" :span="11"
                  ><el-form-item label="服务费用：">
                    {{ info.money ? info.money : "-" }}</el-form-item
                  ></el-col
                >
                <el-col :span="11">
                  <el-form-item label="开票金额：">{{
                    info.financeTask.payAmount
                      ? info.financeTask.payAmount
                      : "-"
                  }}</el-form-item></el-col
                >
                <el-col :offset="1" :span="11"
                  ><el-form-item label="约定开票时间：">
                    {{
                      info.financeTask.conventionMakeInvoiceDate
                        ? info.financeTask.conventionMakeInvoiceDate.replace(
                            RegExp("/", "g"),
                            "-"
                          )
                        : "-"
                    }}</el-form-item
                  ></el-col
                >
                <el-col :span="11">
                  <el-form-item label="约定到账时间：">{{
                    info.financeTask.conventionToAccountDate
                      ? info.financeTask.conventionToAccountDate.replace(
                          RegExp("/", "g"),
                          "-"
                        )
                      : "-"
                  }}</el-form-item></el-col
                >
                <el-col :offset="1" :span="11"
                  ><el-form-item label="付款通知书：">
                    <span
                      class="tabHref mgr10"
                      @click="handleDown(item)"
                      v-for="(item, index) in info.fileList"
                      :key="index"
                      ><span @click="previewOpen(item)"
                        >{{ item.fileName }}
                      </span>
                      <i
                        @click="handleDown(item)"
                        class="el-icon-download"
                        style="margin-left: 15px"
                      ></i></span></el-form-item
                ></el-col>
                <el-col :span="24"
                  ><el-form-item label="备注：">
                    {{ info.financeTask.remark }}</el-form-item
                  ></el-col
                >
              </el-row>
            </el-form>
          </el-collapse-item>
          <el-collapse-item
            title="无合同开票申请信息"
            name="1"
            v-if="info.financeTask"
          >
            <template slot="title">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <p>无合同开票申请信息</p>
                <p
                  v-if="info.totalNoContractApplyByCustomer > 0"
                  class="tabHref"
                  style="margin: 0 8px"
                  @click.stop="showHistory"
                >
                  客户共{{ info.totalNoContractApplyByCustomer }}次历史申请记录
                </p>
              </div>
            </template>
            <el-form label-width="110px" label-position="left">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="申请原因：">
                    {{
                      info.financeTask.noContractReason
                        ? info.financeTask.noContractReason
                        : "-"
                    }}
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="约定签约时间：">
                    {{
                      info.financeTask.agreedSignDate
                        ? info.financeTask.agreedSignDate
                        : "-"
                    }}
                    <span
                      v-if="isShowTips"
                      class="tag tag05"
                      style="margin-left: 8px"
                      >已超期</span
                    >
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="证明材料：">
                    <p
                      class="tabHref mgr10"
                      v-for="(item, index) in info.noContractProveFiles"
                      :key="index"
                    >
                      <span @click="previewOpen(item)"
                        >{{ item.fileName }}
                      </span>
                      <i
                        @click="handleDown(item)"
                        class="el-icon-download"
                        style="margin-left: 15px"
                      ></i>
                    </p>
                    <p v-if="info.noContractProveFiles == null">-</p>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="情况说明：">
                    {{
                      info.financeTask.noContractComment
                        ? info.financeTask.noContractComment
                        : "-"
                    }}
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="关联合同：">
                    {{
                      info.noContractRelatedContractName
                        ? info.noContractRelatedContractName
                        : "-"
                    }}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-collapse-item>
          <!--            分配方案-->
          <el-collapse-item title="分配方案" name="3">
            <el-collapse>
              <el-collapse-item
                v-for="(item, index) in info.performanceAllocations"
                :key="index"
              >
                <template slot="title">
                  {{ item.roleName }}：{{ item.userName }} ({{ item.percent }}%)
                </template>
                <el-form label-width="60px" label-position="left">
                  <el-row>
                    <el-col :span="11">
                      <el-form-item label="集团：">
                        <span
                          v-for="(_item, idx) in item.allocationItems
                            ? item.allocationItems.groups
                            : []"
                          :key="idx"
                          >{{ _item.departmentName }} -
                          {{ _item.percent }}%<span
                            v-if="
                              item.allocationItems.groups &&
                              idx != item.allocationItems.groups.length - 1
                            "
                            >、</span
                          ></span
                        >
                      </el-form-item>
                    </el-col>
                    <el-col :offset="1" :span="11">
                      <el-form-item label="地区：">
                        <span
                          v-for="(_item, idx) in item.allocationItems
                            ? item.allocationItems.areas
                            : []"
                          :key="idx"
                          >{{ _item.departmentName }} -
                          {{ _item.percent }}%<span
                            v-if="
                              item.allocationItems.areas &&
                              idx != item.allocationItems.areas.length - 1
                            "
                            >、</span
                          ></span
                        >
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="11">
                      <el-form-item label="团队：">
                        <span
                          v-for="(_item, idx) in item.allocationItems
                            ? item.allocationItems.teams
                            : []"
                          :key="idx"
                          >{{ _item.departmentName }} -
                          {{ _item.percent }}%<span
                            v-if="
                              item.allocationItems.teams &&
                              idx != item.allocationItems.teams.length - 1
                            "
                            >、</span
                          ></span
                        >
                      </el-form-item>
                    </el-col>
                    <el-col :offset="1" :span="11">
                      <el-form-item label="子团队：">
                        <span
                          v-for="(_item, idx) in item.allocationItems
                            ? item.allocationItems.subTeams
                            : []"
                          :key="idx"
                          >{{ _item.departmentName }} -
                          {{ _item.percent }}%<span
                            v-if="
                              item.allocationItems.subTeams &&
                              idx != item.allocationItems.subTeams.length - 1
                            "
                            >、</span
                          ></span
                        >
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-collapse-item>
            </el-collapse>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col :span="7" style="padding-left: 0">
        <div class="canRecordBox">
          <div class="canRecord">审批流程</div>
          <el-timeline class="canRecordcon">
            <el-timeline-item
              v-for="(activity, index) in info.approvalFlowList"
              :key="index"
              :icon="activity.icon"
              :type="activity.type"
              :color="colorFilter(activity.result)"
              :size="activity.size"
            >
              <div class="approvalTime">{{ activity.approvalTime }}</div>
              <div
                :style="
                  activity.result == 'PENDING'
                    ? 'color: #606266'
                    : 'color: #999'
                "
              >
                {{ activity.content }}
              </div>
              <div
                v-if="activity.comment"
                class="clearfix"
                :style="
                  activity.result == 'REJECTED'
                    ? 'color: #e60012'
                    : 'color: #526ecc'
                "
              >
                <span class="fl" style="display: inline-block">{{
                  activity.result == "REJECTED" ? "驳回理由：" : "通过理由："
                }}</span>
                <div
                  class="fl"
                  style="width: calc(100% - 65px)"
                  v-html="activity.comment"
                ></div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-col>
    </el-row>
    <div
      class="operateBtn"
      v-if="info && info.status == 'PENDING' && info.canApprove && !onRead"
    >
      <el-button
        type="primary"
        plain
        size="mini"
        @click="((isRejectDialog = true), (digTit = '驳回')), clearForm()"
        >审批驳回</el-button
      >
      <el-button
        type="primary"
        plain
        size="mini"
        @click="((isRejectDialog = true), (digTit = '通过')), clearForm()"
        >审批通过</el-button
      >
    </div>
    <!--    驳回/通过-->
    <el-dialog
      :title="digTit"
      :visible.sync="isRejectDialog"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      width="600px"
      :before-close="cancel"
      class="invoiceNumberInput"
    >
      <!--      驳回-->
      <p class="title">请确认是否{{ digTit == "驳回" ? "驳回" : "通过" }}？</p>
      <el-form
        @submit.native.prevent
        label-width="100px"
        :model="form"
        ref="form"
      >
        <el-form-item
          label="备注："
          prop="comment"
          :rules="
            digTit == '驳回'
              ? {
                  required: true,
                  message: '请输入备注',
                  trigger: 'blur',
                }
              : {}
          "
        >
          <el-input
            type="textarea"
            maxlength="100"
            :rows="3"
            placeholder="请输入备注"
            v-model.trim="form.comment"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          plain
          size="mini"
          @click="isRejectDialog = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="saveInfoloading"
          @click="SaveReject()"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 历史记录 -->
    <el-drawer
      :visible.sync="isShowHistory"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">历史申请记录</div>
      <div class="drawerCon">
        <history
          ref="history"
          v-if="isShowHistory"
          :projectResumeId="projectResumeId"
          :applyId="id"
        ></history>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import * as api from "../../api/http";
import {
  noContractFinanceDetail, //无合同审批详情
  noContractFinanceApprove, //无合同审批审批
} from "../../api/api";
import history from "../../components/finance/history";
export default {
  props: ["id", "onRead"],
  components: { history },
  data() {
    return {
      listLoading: false,
      info: {},
      activeNames: ["1", "2", "3"],
      ApiBaseUrl: api.ApiBaseUrl,
      form: {
        comment: null,
      },
      saveInfoloading: false,
      isRejectDialog: false,
      digTit: "",
      handleFinance: true,
      isShowHistory: false,
      projectResumeId: "",
      isShowTips: false,
    };
  },
  created() {
    this.getDetail();
    this.actionUrl = api.ApiBaseUrl + "/api/file/uploadOne";
  },
  methods: {
    getDetail() {
      this.listLoading = true;
      noContractFinanceDetail({
        id: this.id,
      })
        .then((res) => {
          this.info = res.result;
          //   Object.assign(this.info, res.result);
          this.listLoading = false;
          let date1_s = this.info.financeTask.agreedSignDate.replace(
            /\-/g,
            "/"
          );
          let date1_unix = Date.parse(date1_s);
          let date2_unix = +new Date();
          if (date1_unix < date2_unix) {
            this.isShowTips = true;
          } else {
            this.isShowTips = false;
          }
        })
        .catch((_) => {
          this.listLoading = false;
        });
    },
    handleDown(row) {
      //下载文件
      location.href =
        api.ApiBaseUrl +
        "/api/file/download?fileName=" +
        encodeURIComponent(row.fileName) +
        "&filePath=" +
        row.filePath;
    },
    //预览
    previewOpen(item) {
      // let item = this.versionVal;
      const fileType =
        item.fileName.split(".")[item.fileName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xlsx" ||
        fileType == "pptx" ||
        fileType == "ppt"
      ) {
        //wps预览
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            api.PreviewUrl +
            item.filePath,
          "_blank"
        );
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(
          "/pdf/web/viewer.html?file=" + api.PreviewUrl + item.filePath,
          "_blank"
        );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        window.open(api.PreviewUrl + item.filePath, "_blank");
      } else if (fileType) {
        this.$message.error("不支持预览");
      }
    },
    // 驳回
    SaveReject() {
      this.saveInfoloading = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          let queryData = {
            result: this.digTit == "驳回" ? "REJECTED" : "PASS",
            comment: this.form.comment,
            id: this.id,
          };
          noContractFinanceApprove(queryData).then((res) => {
            if (res.success) {
              this.saveInfoloading = false;
              this.isRejectDialog = false;
              this.$message.success("操作成功");
              this.cancel();
              this.$emit("close", "approve");
            } else {
              this.saveInfoloading = false;
            }
          });
        } else {
          this.saveInfoloading = false;
        }
      });
    },
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     this.cancel();
      //     done();
      //   })
      //   .catch((_) => {});
      this.cancel();
      done();
    },

    cancel() {
      this.isReject = false;
      this.invalid = false;
      this.canCompleteInvoice = false;
      this.canCompleteFinance = false;
      this.isfinancialTask = false;
      this.dialogVisible = false;
      this.fileList = [];
      this.isRejectDialog = false;
      this.isShowHistory = false;
    },
    clearForm() {
      this.form.remark = null;
      this.form.version = 0;
      this.form.noTaxMoney = null;
      this.form.amount = null;
      this.form.invalid = null;
      this.form.gllueId = null;
      this.form.id = null;
    },
    colorFilter(val) {
      if (val == "PENDING") {
        return "#e60012";
        // return "#4ac893";
      } else if (val == 0) {
        return "#e4e7ed";
        // return "#2b80ff";
      } else if (val == 3) {
        return "#e4e7ed";
        // return "#999999";
      } else {
        return;
      }
    },
    //显示历史记录
    showHistory() {
      this.isShowHistory = true;
      this.projectResumeId = this.info.financeTask.projectResumeId;
    },
  },
};
</script>

<style lang="scss">
.invoiceNumberInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.noComtractApporvalDialog {
  padding: 15px;
  .el-form-item {
    margin-bottom: 0;
    line-height: 28px;
  }
  .el-form-item__label,
  .el-form-item__content {
    line-height: 28px;
    font-size: 13px;
    color: #606266;
  }
  .el-form-item__content {
    color: #333;
  }
  .contral {
    right: 30px;
    position: absolute;
    color: #999;
    i:hover {
      color: #e60012;
    }
    .icon-kh_gsxq_bj {
      font-size: 16px;
      font-weight: normal;
      margin-right: 15px;
    }
    .el-icon-delete {
      font-size: 18px;
    }
  }
  .titleRecord {
    // overflow:hidden;
    line-height: normal;
    margin-bottom: 15px;
    vertical-align: middle;
    span {
      font-size: 16px;
      line-height: 16px;
    }
  }
  .el-collapse-item__wrap,
  .el-collapse,
  .el-collapse-item__header {
    border: none;
  }
  .el-collapse-item__arrow {
    color: #bbbbbb;
    margin: 0 0 0 auto;
    font-size: 12px;
  }
  .el-collapse-item__header {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    background: #f5f5f5;
    position: relative;
  }
  .el-collapse-item {
    border: 1px solid #eee;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-collapse-item__content {
    border-top: solid 1px #ededed;
    padding: 10px;
    font-size: 13px;
    color: #666666;
    .el-row {
      line-height: 26px;
    }
  }
  .contractInvoices {
    border: solid 1px #ededed;
    border-left: 2px #777788 solid;
    padding: 20px 20px 5px 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: 13px;
    .el-col-12 {
      margin-bottom: 15px;
    }
  }
  .approvalTime {
    color: #999;
  }
  .el-timeline-item {
    padding-bottom: 1px;
    div {
      margin-bottom: 4px;
    }
    .el-timeline-item__wrapper {
      margin-bottom: 15px;
    }
  }
}
.msgClass {
  width: 600px;
}
</style>
<style lang="scss" scoped>
.canRecordBox {
  border: 1px solid #eee;
  position: relative;
  line-height: normal;
  .canRecord {
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px;
    color: #333333;
    font-size: 14px;
    background: #f5f5f5;
  }
  .canRecordcon {
    font-size: 13px;
    padding: 0 20px;
    max-height: 60vh;
    overflow: auto;
  }
}

.el-row {
  line-height: 40px;
}

.operateBtn {
  text-align: center;
  border-top: 1px solid #ededed;
  line-height: 50px;
  position: fixed;
  bottom: 20px;
  width: calc(100% - 40px);
  background-color: #f9f9f9;
  .el-button + .el-button {
    margin-left: 8px;
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 15px 15px;
  line-height: 40px;
}
</style>
